import Image from 'next/image'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Stack } from '@tofu/shared/ui/atoms/stack'
import { Container } from '@tofu/shared/ui/atoms/container'
import { SimpleGrid } from '@tofu/shared/ui/atoms/simple-grid'

import { TSliceImageAndTextList } from './slice-image-and-text-list.types'

export const SliceImageAndTextList: TSliceImageAndTextList = ({
  image,
  items,
  background,
  optional_image_blur_data_url
}) => (
  <Box bg={background} pt={[0, 0, 0, 10]} pb={10}>
    <Container
      maxW='container.xl'
      p={{
        base: 0,
        lg: 4
      }}
    >
      <Stack
        spacing={10}
        alignItems='center'
        direction={['column', 'column', 'column', 'row']}
      >
        <Box width={'100%'}>
          {image.url && (
            <Image
              alt={image?.alt || ''}
              src={image.url}
              width={`589px`}
              height={`422px`}
              layout='responsive'
              {...(optional_image_blur_data_url
                ? {
                    placeholder: 'blur',
                    blurDataURL: optional_image_blur_data_url
                  }
                : /* istanbul ignore next */
                  {})}
            />
          )}
        </Box>
        <Container
          maxW={'container.md'}
          sx={{
            '@media screen and (min-width: 1100px)': {
              width: '60%'
            },
            '@media screen and (min-width: 1200px)': {
              minWidth: '50%'
            }
          }}
        >
          <SimpleGrid columns={[1, 1, 2, 1]} spacing={[8, 8, 8, 10]}>
            {items.map((item, index) => (
              <Box key={index}>
                <Text textStyle='body-xl' fontWeight='medium'>
                  {item.title}
                </Text>
                <Text fontWeight='book'>{item.sub_title}</Text>
              </Box>
            ))}
          </SimpleGrid>
        </Container>
      </Stack>
    </Container>
  </Box>
)
